import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoCompanyGuard } from '@/common/guards';
import { LayoutComponent } from '@/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@/pages/landing/landing-user/landing-user.module').then(m => m.LandingUserModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('@/pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'register',
    loadChildren: () => import('@/pages/app-link-register/app-link-register.module').then(m => m.AppLinkRegisterModule),
  },
  {
    path: 'errors',
    loadChildren: () => import('@/pages/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: 'landing-company',
    loadChildren: () => import('@/pages/landing/landing-company/landing-company.module').then(m => m.LandingCompanyModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('@/pages/landing/privacy/privacy.module').then(m => m.PrivacyModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('@/pages/landing/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('@/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      //   data: { navbarItem: 'dashboard', title: 'DASHBOARD.TITLE' },
      // },
      {
        path: 'offers',
        loadChildren: () => import('@/pages/offers/offers.module').then(m => m.OffersModule),
        canActivate: [NoCompanyGuard],
        data: { navbarItem: 'offers', title: 'OFFERS.TITLE' },
      },
      {
        path: 'chat',
        loadChildren: () => import('@/pages/chat/chat.module').then(m => m.ChatModule),
        canActivate: [NoCompanyGuard],
        data: { navbarItem: 'chat', title: 'CHAT.TITLE' },
      },
      {
        path: 'company',
        loadChildren: () => import('@/pages/companies/companies.module').then(m => m.CompaniesModule),
        data: { title: 'COMPANIES.TITLE' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/errors/not_found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
